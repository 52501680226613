import React, { useState, useEffect } from 'react';
import './App.css'; // Ensure you have appropriate CSS for styling
import LazyImage from './LazyImage'; // Import the LazyImage component


function HeroSection() {
  return (
    <section className="hero-section" style={{ backgroundImage: 'url(/hero_banner.png)' }} id='home' >
      <div className="overlay reveal">
        <h1>SG EV Hub</h1>
        <p>Download our app now and locate EV chargers effortlessly!</p>
        <div className="buttons">
          <a href="https://apps.apple.com/us/app/sg-ev-hub/id6737555881" className="btn">
          <LazyImage src="/ios-download-btn.png" alt="Download SG EV HUB on the App Store" style={{ width: '150px', border: '1px solid white', borderRadius: '10px' }} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.sgevhub.app" className="btn">
          <LazyImage src="/android-download-btn.png" alt="Download SG EV HUB on the Google Play" style={{ width: '150px', border: '1px solid white', borderRadius: '10px' }} />
          </a>
        </div>
      </div>
    </section>
  );
}

function FeaturesSection() {
  const features = [
    {
      title: 'Locate a charge with ease',
      description: 'Find a charging station nearby and even get directions to it with a single tap.',
      image: 'Point1.png'
    },
    {
      title: 'Organize & manage your charging stations',
      description: 'Save your favorite stations for quick and easy access. Filter stations by type, location, and more.',
      image: 'Point2.png'
    },
    {
      title: 'Share your charging experience',
      description: 'Access reviews from other EV users and share your own experiences with Singapore\'s EV community.',
      image: 'Point3.png'
    }
    
  ];

  return (
    <section className="features-section" id="features">
      <h2>Why SG EV Hub?</h2>
      <hr className="features-divider" />
      {features.map((feature, index) => (
        <div className={`feature ${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
          <LazyImage src={feature.image} alt={feature.title} className="feature-image reveal" />
          
          <div className="feature-text reveal">
            <h2>{feature.title}</h2>
            <p>{feature.description}</p>
          </div>
        </div>
      ))}
    </section>
  );
}

function CollaborationSection() {
  const operators = [
    'SP Group.png','CDG Engie.png', 'Keppel.png', 'Shell.png', 'chargeco.png', 'Blink.png', 'Bluecharge.png', 'Charge_plus.png', 
    'ChargeSini.png', 'ChargEV.png', 'erge.png', 'EVCS.png', 'jomcharge.png',
    'Noodoe.png', 'parkeasy.png', 'QuickChargesg.png', 
     'Supercharger.png'
  ];

  return (
    <section className="collaboration-section reveal" id="operators">
      <h2>Feature Operators</h2>
      <hr className="features-divider" />
      <div className="operators-grid">
        {operators.map((operator, index) => (
          <LazyImage src={`/icons/${operator}`}  alt={`Operator ${index + 1}`} className="operator-icon" key={index} />
        ))}
      </div>
    </section>
  );
}


function UserReview() {
  
  return (
    <section className="review-section reveal">
      <h2>Happy Customer</h2>
      <hr className="features-divider" />
      <div className="review-div">
        
          <LazyImage src='/review banner.jpg' alt='user reviews' className="review-image" />
       
      </div>
    </section>
  );
}


function Home() {
  useEffect(() => {
    const revealElements = document.querySelectorAll('.reveal');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('active');
        }
      });
    });

    revealElements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      revealElements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <div className="App">
      <HeroSection />
      <FeaturesSection />
      <CollaborationSection />
      <UserReview/>
    </div>
  );
}

export default Home;