// src/NewPage.js
import React from 'react';
import './App.css'; // Ensure you have appropriate CSS for styling


const AboutUs = () => {
  return (
    <div className="App">
      <h1>About SG EV Hub</h1>
      <p>SG EV Hub is an app designed to assist EV owners in finding charging stations across Singapore. 
      </p><p>  
      As EV adoption is still in its early stages in Singapore, our goal is to build a community where everyone can contribute and make EV ownership more convenient.
      </p><p> 
      For new EV owners, the multitude of operator apps can be overwhelming. Our app consolidates the locations of different operators into a single platform, making it easy to find nearby charging stations.
      </p><p> 
      All location data is curated by our community through submissions, and our team verifies the information to ensure its accuracy. We will introduce more enhancements soon, including integration with various charging operators, eliminating the need to use multiple apps.
      </p>
    </div>
  );
};

export default AboutUs;