// src/NewPage.js
import React from 'react';
import './App.css'; // Ensure you have appropriate CSS for styling


const ContactUs = () => {
  return (
    <div className="App">
      <h1>Contact Us</h1>
      <p>You may contact us at <a href="mailto:support@sgevhub.com">support@sgevhub.com</a></p>
      <p>We will get back to you as soon as possible.</p>
      <p>Thanks for your support!</p>
    
    </div>
  );
};

export default ContactUs;