// src/NewPage.js
import React from 'react';
import './App.css'; // Ensure you have appropriate CSS for styling


const TermsService = () => {
  return (
    <div className="App">
      <h1>Terms of Service</h1>
      <p>
      <b>1. Introduction</b></p><p>
              Welcome to SG EV Hub. By using our app, you agree to these Terms of Service. Please read them carefully.</p><p>
              <b>2. Services Provided</b></p><p>
              SG EV Hub helps electric vehicle (EV) owners find charging stations in Singapore. Our app consolidates locations from various operators into one platform, making it easier to find nearby charging stations.</p><p>
              <b>3. User Contributions</b></p><p>
              Users can submit charging station locations. Our team reviews these submissions to ensure accuracy. By submitting information, you grant us the right to use and share this data within the app.</p><p>
              <b>4. Accuracy of Information</b></p><p>
              While we strive to provide accurate and up-to-date information, we cannot guarantee the accuracy, completeness, or reliability of all data. Users should verify details independently when necessary.</p><p>
              <b>5. Community Guidelines</b></p><p>
              We aim to create a supportive community for EV owners. Users must respect others and refrain from submitting false or misleading information. Any misuse of the app may result in suspension or termination of access.</p><p>
              <b>6. Privacy</b></p><p>
              We are committed to protecting your privacy. Please refer to our Privacy Policy for details on how we handle your personal information.</p><p>
              <b>7. Changes to Services</b></p><p>
              We may update or modify the app and its features at any time. We will notify users of significant changes through the app or other communication channels.</p><p>
              <b>8. Limitation of Liability</b></p><p>
              SG EV Hub is not liable for any direct, indirect, incidental, or consequential damages arising from the use of the app. Users assume full responsibility for their use of the app and any decisions made based on the information provided.</p><p>
              <b>9. Contact Us</b></p><p>
              If you have any questions or concerns about these Terms of Service, please contact us.
              </p>
    </div>
  );
};

export default TermsService;