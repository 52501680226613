import React from 'react';
import './App.css'; // Ensure you have appropriate CSS for styling
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'; 
import LazyImage from './LazyImage'; // Import the LazyImage component


const Footer = () => {
    return (
      <footer className="App-footer"> 
      <h2>Download Our App</h2>
      <div className="footer-buttons">
      <a href="https://apps.apple.com/us/app/sg-ev-hub/id6737555881" className="footer-btn">
      <LazyImage src="/ios-download-btn.png" alt="Download SG EV HUB on the App Store" style={{ width: '150px', border: '1px solid white', borderRadius: '10px' }} />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.sgevhub.app" className="footer-btn">
      <LazyImage src="/android-download-btn.png" alt="Download SG EV HUB on the Google Play" style={{ width: '150px', border: '1px solid white', borderRadius: '10px' }} />
      </a>
      </div>
      <div className="social-links">
      <a href="https://www.facebook.com/people/SG-EV-HUB/61566980022806/">
      <FaFacebook className="social-icon" />
      </a>
      <a href="https://www.instagram.com/sg_ev_hub/">
      <FaInstagram className="social-icon" />
      </a>
      </div>
      </footer>
    );
  }

  export default Footer;