// src/FAQs.js
import React, { useState } from 'react';
import './FAQs.css'; // Ensure you have appropriate CSS for styling

const FAQs = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleFAQ = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="App">
      <h1>FAQs</h1>
      <div className="faq-list">
        <div className="faq-item">
          <h2 onClick={() => toggleFAQ(0)}>Types of EV Charger Stations in Singapore</h2>
          {expandedIndex === 0 && (
            <div className="faq-content">
              <p>In Singapore, EV charger stations can primarily be categorised into 2 different types:</p>
              <ul>
                <li><strong>Type 2 Charger:</strong> This charger uses alternating current (AC) to charge electric vehicles. It is commonly found in many charging stations across the country.</li>
                <li><strong>Combined Charging System 2 (CCS2):</strong> Unlike the Type 2 charger, the CCS2 charger utilises direct current (DC) for faster charging.</li>
              </ul>
            </div>
          )}
        </div>
        <div className="faq-item">
          <h2 onClick={() => toggleFAQ(1)}>What is the difference between AC and DC charging?</h2>
          {expandedIndex === 1 && (
            <div className="faq-content">
              <p>The majority of public charging stations use AC charging, with output ranging from 3.7kW to 43kW locally. DC chargers have much higher outputs (50kW to 120kW), which allow for much quicker charging times.</p>
            </div>
          )}
        </div>
        <div className="faq-item">
          <h2 onClick={() => toggleFAQ(2)}>How long does it take to charge an EV?</h2>
          {expandedIndex === 2 && (
            <div className="faq-content">
              <p>Depending on the car's battery capacity, onboard charger and state of charge, AC charging can take between 4 to 8 hours. DC charging is much quicker - typically taking less than an hour to charge a battery to 80%.</p>
            </div>
          )}
        </div>
        <div className="faq-item">
          <h2 onClick={() => toggleFAQ(3)}>Is it better to fast charge (DC) or slow charge (AC)?</h2>
          {expandedIndex === 3 && (
            <div className="faq-content">
              <p>In general, slow charging (AC) is better for battery longevity, as it generates less heat and puts less strain on the battery cells. Slow charging at home or at standard public AC chargers provides a steady charge rate, which is ideal for daily top-ups and helps maintain long-term battery health.
              </p>
              <br/>
              <p>Fast charging (DC), on the other hand, is great for convenience when you need a quick boost, such as on long trips or when you're in a hurry. However, repeated use of DC fast charging can generate more heat and potentially accelerate battery degradation over time. Occasional DC charging is fine and often unavoidable, but it’s best to rely on AC charging for regular use whenever possible to keep your battery in optimal condition.</p>
            </div>
          )}
        </div>
        <div className="faq-item">
          <h2 onClick={() => toggleFAQ(4)}>Should I always charge my EV to 100%?</h2>
          {expandedIndex === 4 && (
            <div className="faq-content">
              <p>It's generally best not to charge your EV to 100% regularly unless you need the full range for a long trip. Charging to around 80-90% is ideal for daily driving, as it helps preserve battery health over time. Most EVs allow you to set a charge limit so that you can automatically stop charging before reaching full capacity. Frequent charging to 100% can increase battery wear, especially if the battery then sits fully charged for extended periods. For optimal long-term battery health, aim for partial charges when possible.</p>
            </div>
          )}
        </div>
        <div className="faq-item">
          <h2 onClick={() => toggleFAQ(5)}>Do EVs require less maintenance?</h2>
          {expandedIndex === 5 && (
            <div className="faq-content">
              <p>Yes, EVs generally require less maintenance than traditional gas-powered vehicles due to their simpler design and fewer moving parts.</p>
              <br />
              <p>Overall, the main maintenance tasks for EVs involve tire rotations, brake fluid changes, and cabin air filter replacements, making them relatively easy and cost-effective to maintain over time.</p>
            </div>
          )}
        </div>  
      </div>
    </div>
  );
};

export default FAQs;