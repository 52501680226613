// src/NewPage.js
import React from 'react';
import './App.css'; // Ensure you have appropriate CSS for styling


const PrivacyPolicy = () => {
  return (
    <div className="App">
      <h1>Terms of Service</h1>
      <p><b>1. Introduction</b></p><p>
        Welcome to SG EV Hub. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner.</p><p>
        <b>2. Information We Collect</b></p><p>
        We collect information that you provide to us directly, such as when you submit charging station locations or contact us for support. We may also collect information automatically, such as usage data and device information.</p><p>
        <b>3. How We Use Your Information</b></p><p>
        We use your information to:{"\n"}
        - Provide and improve our services{"\n"}
        - Verify the accuracy of submitted charging station locations{"\n"}
        - Communicate with you about updates and changes to our services{"\n"}
        - Ensure the security and integrity of our app</p><p>
        <b>4. Sharing Your Information</b></p><p>
        We do not sell or rent your personal information to third parties. We may share your information with trusted partners who assist us in operating our app and providing our services, as long as they agree to keep your information confidential.</p><p>
        <b>5. Data Security</b></p><p>
        We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p><p>
        <b>6. Your Rights</b></p><p>
        You have the right to access, update, or delete your personal information. If you wish to exercise these rights, please contact us at support@sgevhub.com.</p><p>
        <b>7. Changes to This Privacy Policy</b></p><p>
        We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our app.</p><p>
        <b>8. Contact Us</b></p><p>
        If you have any questions or concerns about this Privacy Policy, please contact us.
        </p>
    </div>
  );
};

export default PrivacyPolicy;